import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Image from '@nubank/nuds-web/components/Image/Image';
import Button from '@nubank/nuds-web/components/Button/Button';
import Form from '@nubank/nuds-web/components/Form/Form';
import TextField from '@nubank/nuds-web/components/TextField/TextField';

import { useSiteContext } from '../SiteContext/SiteContext';
import { popUpEvent } from '../../screens/Registration/tracking';
import { getDiscoveryUrls } from '../../domains/discoveryUrls/discoveryUrls';
import { sentryException, ERROR_SEVERITY } from '../../utils/sentry';

const Background = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  background-color: rgba(0 0 0 / 70%);


  .fade-in {
    opacity: 0.1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fade-in.active {
    opacity: 1;
  }
`;

const Container = styled(Box)`
   background: linear-gradient(160deg, #9F2DEB 14%, #6200A3 75%);
   box-shadow: 0 2px 4px rgba(0 0 0 / 20%);

   ${breakpointsMedia({
    xs: css`
      border-radius: 20px 20px 0 0;
      `,
    md: css`
      border-radius: 20px;
    `,
  })}
`;

const CloseButton = styled(Button)`
z-index: 999;
`;

const Title = styled(Typography)`
   ${breakpointsMedia({
    xs: css`
      font-size: 24px;
      `,
    md: css`
      font-size: 26px;
    `,
    lg: css`
      font-size: 30px;
    `,
  })}
`;

const Subtitle = styled(Typography)`
   ${breakpointsMedia({
    xs: css`
      font-size: 16px;
      `,
    md: css`
      font-size: 16px;
    `,
    lg: css`
      font-size: 18px;
    `,
  })}
`;

const StyledTextField = styled(TextField)`
  background-color: #fff3;
  border-bottom: none;
  border-radius: 6.25rem;
  caret-color: #FFF;
  color: #FFF;
  height: 3rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  &::placeholder {
    color: #FFF;
    font-size: 14px;
    position:relative;
    text-align: center;
    top:20%;
    transform:translateY(-50%); 
  }

  &.red-error {
    &::placeholder {
      color: #D72923;
    }

    p {
      display: none;
    }
  }

  :invalid, :valid, :-webkit-autofill, :autofill {
    background-color: #fff3;
    border:  none;
    border-radius: 6.25rem;
    caret-color: #FFF;
    height: 3rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  :user-invalid {
    border: 2px solid #D72923;
  }
`;

const StyledBox = styled(Box)`
  span, p {
    display: none;
  }

  p#error-email {
    display: block;
    color: #FFD8D5;
    text-align: center;
  }

  div {
    min-height: auto;
  }
`;

const ApplyButton = styled(Button)`
  display: flex;
  justify-content: center;
`;

function ApplyPopUp({ setIsPopUpOpen, isPopUpOpen }) {
  const {
    updateDisplayPopUpContext,
    setEmail,
    openRegistrationForm,
    discoveryUrlsList,
    updateDiscoveryUrlsList,
  } = useSiteContext();
  const { formatMessage } = useIntl();
  const [active, setActive] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [sendEmailEvent, setSendEmailEvent] = useState(true);

  useEffect(() => {
    // Trigger event if the user inputs an email
    if (emailInput && emailInput.length > 3 && sendEmailEvent) {
      setSendEmailEvent(false);
      popUpEvent('email');
    }
  }, [emailInput]);

  useEffect(() => {
    // Trigger animation after a short delay to ensure proper rendering
    setTimeout(() => setActive(true), 10);
  }, []);

  // Open and close modal
  const handleClose = source => {
    updateDisplayPopUpContext(false);
    setActive(false);
    setIsPopUpOpen(false);
    if (source) {
      popUpEvent(`close ${source}`);
    }

    setTimeout(isPopUpOpen, 100);
  };

  // const handleOverlayClick = e => {
  //   if (e.target === e.currentTarget) {
  //     handleClose('overlay');
  //   }
  // };

  const inputLabel = 'Escribe tu correo aquí';
  const [placeholder, setPlaceholder] = useState(inputLabel);

  const handleOnClick = () => {
    setPlaceholder('FORMBOX.INPUT.EMPTY.VALIDATION_MESSAGE');
    const placeholders = document.querySelectorAll('#email-on-hero');

    placeholders.forEach(element => {
      element.classList.add('red-error');
    });
  };

  const handleSubmit = async ({
    values,
    setSubmitting,
  }) => {
    if (values.email) {
      try {
        setEmail(values.email);
        handleClose();
        openRegistrationForm();
        if (!discoveryUrlsList) {
          const discoveryUrls = await getDiscoveryUrls();
          updateDiscoveryUrlsList(discoveryUrls);
        }
      } catch (error) {
        sentryException({
          error,
          flow: 'application_flow',
          checkpoint: 'set email and open registration form',
          namespace: '<FormBox/>',
          level: ERROR_SEVERITY.CRITICAL,
        });
      } finally {
        setSubmitting(false);
        popUpEvent('start button');
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Background
      display="flex"
      justifyContent="center"
      alignItems={{ xs: 'flex-end', md: 'center' }}
      paddingTop={{ xs: '40px', lg: '0' }}
      // onClick={handleOverlayClick}
    >
      <Container
        position="relative"
        width={{ xs: '100%', md: '80%', lg: '70%' }}
        className={`fade-in ${active ? 'active' : ''}`}
        paddingTop={{ xs: '2x', md: '4x' }}
        paddingBottom={{ xs: '8x', md: '12x' }}
        maxWidth="768px"
      >

        {/* CLOSE BUTTON */}
        <Box
          display="flex"
          justifyContent="flex-start"
          width="100%"
          paddingHorizontal={{ xs: '2x', md: '4x' }}
          zIndex="999"
        >
          <CloseButton
            variant="basic"
            styleVariant="white"
            iconProps={{ name: 'x', title: 'Cerrar' }}
            onClick={() => handleClose('button')}
          />
        </Box>

        {/* TOP IMAGE */}
        <Box
          position="relative"
          top={{ xs: '-115px', md: '-135px' }}
          display="flex"
          justifyContent={{ xs: 'center' }}
        >
          <Image
            alt="Icono de una bolsa"
            src="components/apply-pop-up/celebration.svg"
            width={{ xs: '110px', md: '121px' }}
            height={{ xs: '110px', md: '121px' }}
            webp={false}
          />
        </Box>

        {/* CONTENT */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginTop={{ xs: '-100px', md: '-130px' }}
          marginBottom="4x"
          paddingHorizontal="4x"
        >
          <Title
            variant="heading3"
            color="white"
          >
            ¡Nu tiene una oferta para ti!
          </Title>
          <Subtitle
            variant="subtitle1"
            color="white"
            marginTop="4x"
            textAlign="center"
          >
            Empieza ahora para descubrir qué podemos ofrecerte.
          </Subtitle>
        </Box>

        {/* MODAL CONTENT ENDS */}

        <Box
          display="flex"
          flexDirection="column"
          alignItems={{ xs: 'center' }}
          justifyContent="center"
        >
          <Box width={{ xs: '70%' }} maxWidth="335px">
            <Form>
              <Form.Step
                initialValues={{ email: '' }}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {() => (
                  <>
                    <StyledBox>
                      <StyledTextField
                        id="email-on-hero"
                        type="email"
                        name="email"
                        label=""
                        placeholder={formatMessage({ id: placeholder })}
                        syncValidations={{
                          required: formatMessage({ id: 'FORMBOX.DEFAULT.INPUT.VALIDATION_MESSAGE' }),
                          email: formatMessage({ id: 'FORMBOX.DEFAULT.INPUT.VALIDATION_MESSAGE' }),
                        }}
                        onInput={e => setEmailInput(e.target.value)}
                      />
                    </StyledBox>
                    <ApplyButton
                      id="email-pop-on-submit-btn"
                      type="submit"
                      variant="contained"
                      styleVariant="white"
                      iconProps={{ name: 'arrow-right', title: 'Some descriptive title' }}
                      extended
                      onClick={() => handleOnClick()}
                    >
                      Empezar registro
                    </ApplyButton>
                  </>
                )}
              </Form.Step>

            </Form>

          </Box>
        </Box>

      </Container>
    </Background>
  );
}

ApplyPopUp.defaultProps = {
};

ApplyPopUp.propTypes = {
  isPopUpOpen: PropTypes.func.isRequired,
  setIsPopUpOpen: PropTypes.func.isRequired,
};

export default ApplyPopUp;

import React, { useContext } from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import styled from 'styled-components';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';

import RouterLink from 'components/RouterLink/RouterLink';
import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { typeValidation } from '../../utils';
import { MenuContext } from '../../MenuContext';
import { HERO_IMAGE_EXPERIMENT_ID, HERO_IMAGE_EXPERIMENT_VARIANT_ID } from '../../../../utils/experiment';

export const LogoWrapper = styled(Box)`
  line-height: 0;
`;

const Logo = () => {
  const {
    isHovered, isWhiteColorHero, isMobileNavOpen, userScrolledBelowTheHero,
  } = useContext(MenuContext);

  const activeVariant = useFigPiiExperiment(HERO_IMAGE_EXPERIMENT_ID);

  return (
    <Box>
      <LogoWrapper>
        <RouterLink href="/">
          <NuLogo variant={isWhiteColorHero || isHovered || isMobileNavOpen || userScrolledBelowTheHero || activeVariant === HERO_IMAGE_EXPERIMENT_VARIANT_ID ? 'primary' : 'white'} size="small" />
        </RouterLink>
      </LogoWrapper>
    </Box>
  );
};

Logo.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('Logo'),
};

Logo.defaultProps = {
  __TYPE: 'Logo',
};

export default Logo;

import React from 'react';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import StyledCollapse from '../styles/StyledCollapse';

function ContactAndHelpLinks() {
  return (
    <Grid.Child>
      <StyledCollapse id="contact" initiallyOpen>
        <Collapse.Header>
          <Typography
            color="white.default"
            variant="subtitle1"
            strong
          >
            Contacto y Ayuda
          </Typography>
        </Collapse.Header>

        <Collapse.Body>
          <Box
            display="flex"
            flexDirection="column"
            marginTop="4x"
          >
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/atencion-a-clientes"
            >
              Atención a clientes 24/7
            </Link>
            <Typography
              variant="paragraph1"
              marginBottom="2x"
              strong
              color="white.default"
            >
              Contáctonos al chat de la app
            </Typography>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="mailto:ayuda@nu.com.mx"
            >
              Escribe a nuestro correo ayuda@nu.com.mx
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="tel:+52 55 9225 2622"
              variant="action"
              width="fit-content"
            >
              +52 55 9225 2622
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/ayuda"
            >
              Preguntas frecuentes
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
              href="/une"
              marginBottom="8x"
            >
              Unidad Especializada  de
              Atención a Usuarios (UNE)
            </Link>
          </Box>

        </Collapse.Body>
      </StyledCollapse>
    </Grid.Child>
  );
}

export default ContactAndHelpLinks;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@nubank/nuds-web/components/Button/Button';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { MenuContext } from '../MenuContext';
import { HERO_IMAGE_EXPERIMENT_ID, HERO_IMAGE_EXPERIMENT_VARIANT_ID } from '../../../utils/experiment';

const ButtonToggleStyled = styled(Button)`
  &.menu--button-toggle__close {
    svg {
      width: 20px;
      height: 20px;
    }

    :hover::before {
      background: none;
    } 
  }
`;

function ButtonToggle(props) {
  const {
    isHovered,
    userScrolledBelowTheHero,
    isWhiteColorHero,
  } = useContext(MenuContext);
  const {
    onMenuToggleClick,
    isMobileNavOpen,
    className,
    iconProps,
  } = props;

  const iconTitle = isMobileNavOpen
    ? iconProps.titleClose
    : iconProps.titleOpen;

  const activeVariant = useFigPiiExperiment(HERO_IMAGE_EXPERIMENT_ID);

  return (
    <ButtonToggleStyled
      variant="basic"
      styleVariant="black"
      onClick={onMenuToggleClick}
      className={isMobileNavOpen ? className : `${className} menu--button-toggle__close`}
      iconProps={{
        name: isMobileNavOpen ? 'x' : 'menu',
        title: iconTitle,
        color: isMobileNavOpen || isHovered || userScrolledBelowTheHero || isWhiteColorHero || activeVariant === HERO_IMAGE_EXPERIMENT_VARIANT_ID ? 'primary' : 'primary',
      }}
    />
  );
}

ButtonToggle.defaultProps = {
  isMobileNavOpen: false,
  className: undefined,
  iconProps: {
    titleOpen: 'Open',
    titleClose: 'Close',
  },
};

ButtonToggle.propTypes = {
  className: PropTypes.string,
  iconProps: PropTypes.shape({
    titleClose: PropTypes.string,
    titleOpen: PropTypes.string,
  }),
  isMobileNavOpen: PropTypes.bool,
  onMenuToggleClick: PropTypes.func.isRequired,
};

export default ButtonToggle;
